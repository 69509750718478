<template>
  <div
    class="w-full mt-2 max-w-1400 h-full mx-auto relative block overflow-hidden"
  >
    <div
      @scroll="isDesktop ? null : handleScroll($event)"
      ref="sliderContainer"
      class="flex flex-nowrap mx-auto h-full p-0 translate-x-0 transition-all duration-500 w-auto snap-x snap-mandatory"
      :class="isDesktop ? '' : 'overflow-x-scroll scrollbar-hidden'"
    >
      <component
        :is="slide.component"
        :blok="slide"
        v-for="(slide, index) in blok.slides"
        :key="index"
        :index="index"
      />
    </div>

    <div
      v-if="isDesktop && blok.slides.length > 1"
      @click="handleChangeSlide(active + 1)"
      class="h-24 w-10 grid place-items-center absolute top-0 bottom-0 right-0 my-auto rounded-tl-md rounded-bl-md bg-white bg-opacity-40 text-neutral-black-1 cursor-pointer"
    >
      <img
        src="@/assets/svg/icons/black/icons-right-arrow-light.svg"
        class="h-8"
      />
    </div>

    <div
      v-if="isDesktop && blok.slides.length > 1"
      @click="handleChangeSlide(active - 1)"
      class="h-24 w-10 grid place-items-center absolute top-0 bottom-0 left-0 my-auto rounded-tr-md rounded-br-md bg-white bg-opacity-40 text-neutral-black-1 cursor-pointer"
    >
      <img
        src="@/assets/svg/icons/black/icons-left-arrow-light.svg"
        class="h-8"
      />
    </div>

    <ul v-if="blok.slides.length > 1" class="mx-auto z-50 w-fit py-3">
      <li
        @click="
          isDesktop ? handleChangeSlide(index) : handleChangeSlideMobile(index)
        "
        v-for="(dot, index) in blok.slides"
        :key="index"
        class="border-2 border-[#212121] rounded-full cursor-pointer block float-left h-2.5 ml-2.5 p-0 transition-all duration-300 w-2.5"
        :class="active == index ? 'bg-[#212121]' : 'bg-transparent'"
      ></li>
    </ul>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
})

const active = ref(0)
const sliderContainer = ref(null)
const autoplay = ref(true)

const { isDesktop } = useDevice()
const scrolling = ref(false)
const changing = ref(false)

const slide = () => {
  if (isDesktop) {
    handleChangeSlide()
  } else {
    handleChangeSlideMobile()
  }
}

const activateInterval = () => {
  if (!autoplay.value) return
  interval = setInterval(() => {
    active.value++
    if (active.value > props.blok.slides.length - 1) {
      active.value = 0
      slide()
      return
    }
    slide()
    return
  }, 10000)
}

let interval
onMounted(() => {
  activateInterval()
})

const handleChangeSlide = (idx) => {
  let n = idx !== undefined ? idx : active.value

  if (!sliderContainer.value) {
    return
  }

  if (n >= props.blok.slides.length) {
    clearInterval(interval)
    n = 0
    active.value = 0
  } else if (n < 0) {
    n = props.blok.slides.length - 1
    active.value = n
  }

  active.value = n
  autoplay.value = false

  if (sliderContainer.value && sliderContainer.value.style) {
    sliderContainer.value.style.transform = `translateX(-${n}00%)`
  } else {
    return
  }

  activateInterval()
}

const handleScroll = (e) => {
  if (scrolling.value) return
  if (!sliderContainer.value) return
  scrolling.value = true
  clearInterval(interval)

  const child = sliderContainer.value.children[0]
  const childWidth = child.clientWidth
  const scrollLeft = sliderContainer.value.scrollLeft
  const n = Math.round(scrollLeft / childWidth)
  active.value = n
  activateInterval()
  scrolling.value = false
}

const handleChangeSlideMobile = (idx) => {
  if (changing.value) return
  changing.value = true
  clearInterval(interval)

  if (!sliderContainer.value) {
    changing.value = false
    return
  }

  let n = idx !== undefined ? idx : active.value
  if (n >= props.blok.slides.length) {
    n = 0
  } else if (n < 0) {
    n = props.blok.slides.length - 1
  }

  active.value = n

  const child = sliderContainer.value.children[n]
  if (!child) {
    changing.value = false
    return
  }

  const childWidth = child.clientWidth
  sliderContainer.value.scrollTo({
    left: n * childWidth,
    behavior: 'smooth',
  })

  changing.value = false
  activateInterval()
}
</script>
